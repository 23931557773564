import React, { createRef } from "react";
import Quill from "quill";
import "./inputs.css";
import { ChromePicker } from "react-color";
import { useDispatch } from "react-redux";
import { getIn, setIn } from "../../utils";
import { updateCurrentCardAction } from "../../store/application";
import { OverlayPanel } from "../primereact/components/overlaypanel/OverlayPanel";

export interface QuillEditorProps {
  name: string;
  singleLine?: boolean;
  onChange: any;
  value: string;
  backgroundColor: string;
}

interface State {
  currentColorPicker: string;
  currentBackgroundPicker: string;
}

export class QuillEditor extends React.Component<QuillEditorProps, State> {
  private quill: Quill | null = null;
  private colorPickerRef = createRef<any>();
  private backgroundPickerRef = createRef<any>();
  private editorName = "";
  private fonts = [
    { value: "roboto", label: "Roboto" },
    { value: "akaya", label: "Akaya Telivigala" },
    { value: "lato", label: "Lato" },
    { value: "opensans", label: "Open Sans" },
    { value: "oswald", label: "Oswald" },
    { value: "ssans", label: "Source Sans Pro" },
    { value: "montserrat", label: "Montserrat" },
    { value: "ubuntu", label: "Ubuntu" },
    { value: "dosis", label: "Dosis" },
    { value: "merri", label: "Merriweather" },
    { value: "playfair", label: "Playfair Display" },
    { value: "lora", label: "Lora" },
    { value: "ptserif", label: "PT Serif" },
    { value: "sourceserif", label: "Source Serif" },
    { value: "arvo", label: "Arvo" },
    { value: "crimson", label: "Crimson" },
    { value: "zilla", label: "Zilla Slab" },
    { value: "ibm", label: "IBM Plex Serif" },
    { value: "libre", label: "Libre Baskerville" },
    { value: "slabo", label: "Slabo 27px" },
    { value: "ebgaramond", label: "EB Garamond" },
  ];

  constructor(props: QuillEditorProps) {
    super(props);

    this.editorName = props.name
      .replace(/\./g, "")
      .replace(/\[/g, "")
      .replace(/\]/g, "");
    this.state = {
      currentColorPicker: "",
      currentBackgroundPicker: "",
    };

    this.handleOnColor = this.handleOnColor.bind(this);
    this.handleOnBackground = this.handleOnBackground.bind(this);
    this.handleOnChangeColorPicker = this.handleOnChangeColorPicker.bind(this);
    this.handleOnChangeBackgroundPicker =
      this.handleOnChangeBackgroundPicker.bind(this);
  }

  componentDidMount() {
    const { value, singleLine = false, backgroundColor = "#fff" } = this.props;

    this.quill = singleLine
      ? this.createSingleLineQuill(this.editorName)
      : this.createNormalQuill(this.editorName);
    this.quill.on("text-change", () => {
      const { onChange } = this.props;
      const html = this.quill?.root.innerHTML || "";
      onChange({
        target: { value: html, name: this.editorName },
      });
    });

    this.quill.root.innerHTML = value;
    this.changeBackgroundColor(backgroundColor);
  }

  private createNormalQuill(name: string) {
    return new Quill(`#${name}-gk-qeditor`, {
      theme: "snow",
      modules: {
        toolbar: `#${name}-gk-toolbar-container`,
      },
    });
  }

  componentDidUpdate(prevProps: QuillEditorProps) {
    if (prevProps.backgroundColor !== this.props.backgroundColor) {
      this.changeBackgroundColor(this.props.backgroundColor);
    }
  }

  private changeBackgroundColor(color: string) {
    const editor = document.getElementById(`${this.editorName}-gk-qeditor`);
    const panel = editor?.getElementsByClassName("ql-editor");
    if (panel && panel.length > 0) {
      (panel[0] as any).style.backgroundColor = color;
    }
  }

  private createSingleLineQuill(name: string) {
    return new Quill(`#${name}-gk-qeditor`, {
      theme: "snow",
      modules: {
        toolbar: `#${name}-gk-toolbar-container`,
        clipboard: {
          newLines: false,
        },
        keyboard: {
          bindings: {
            enter: {
              key: 13,
              handler: function () {
                return false;
              },
            },
          },
        },
      },
    });
  }

  componentWillUnmount() {
    this.quill = null;
  }

  handleOnColor(event: any) {
    event.preventDefault();
    event.stopPropagation();
    this.colorPickerRef.current.toggle(event);
  }

  handleOnBackground(event: any) {
    event.preventDefault();
    event.stopPropagation();
    this.backgroundPickerRef.current.toggle(event);
  }

  handleOnChangeColorPicker(color: any) {
    this.setState({ currentColorPicker: color.hex });
    this.quill?.format("color", color.hex);
  }

  handleOnChangeBackgroundPicker(color: any) {
    this.setState({ currentBackgroundPicker: color.hex });
    this.quill?.format("background", color.hex);
  }

  render() {
    const { singleLine } = this.props;
    const { currentColorPicker, currentBackgroundPicker } = this.state;
    const name = this.editorName;

    return (
      <>
        <div id={`${name}-gk-toolbar-container`}>
          <span className="ql-format-group">
            <select title="Size" className="ql-size">
              <option value="18px">Normal</option>
              <option value="16px">Pequeño</option>
              <option value="20px">Grande</option>
            </select>
          </span>
          <span className="ql-formats">
            <select className="ql-font">
              <option value={"roboto"}>{"Roboto"}</option>
              <option value={"akaya"}>{"Akaya Telivigala"}</option>
              <option value={"lato"}>{"Lato"}</option>
              <option value={"opensans"}>{"Open Sans"}</option>
              <option value={"oswald"}>{"Oswald"}</option>
              <option value={"ssans"}>{"Source Sans Pro"}</option>
              <option value={"montserrat"}>{"Montserrat"}</option>
              <option value={"ubuntu"}>{"Ubuntu"}</option>
              <option value={"dosis"}>{"Dosis"}</option>
              <option value={"merri"}>{"Merriweather"}</option>
              <option value={"playfair"}>{"Playfair Display"}</option>
              <option value={"lora"}>{"Lora"}</option>
              <option value={"ptserif"}>{"PT Serif"}</option>
              <option value={"sourceserif"}>{"Source Serif"}</option>
              <option value={"arvo"}>{"Arvo"}</option>
              <option value={"crimson"}>{"Crimson"}</option>
              <option value={"zilla"}>{"Zilla Slab"}</option>
              <option value={"ibm"}>{"IBM Plex Serif"}</option>
              <option value={"libre"}>{"Libre Baskerville"}</option>
              <option value={"slabo"}>{"Slabo 27px"}</option>
              <option value={"ebgaramond"}>{"EB Garamond"}</option>
            </select>
          </span>
          <span className="ql-formats">
            <button className="ql-bold" />
            <button className="ql-italic" />
            <button className="ql-underline" />
          </span>

          <span className="ql-formats">
            <button className="ql-color" onClick={this.handleOnColor}>
              {" "}
            </button>
            <OverlayPanel ref={this.colorPickerRef} dismissable>
              <ChromePicker
                color={currentColorPicker}
                onChange={this.handleOnChangeColorPicker}
                onChangeComplete={this.handleOnChangeColorPicker}
              />
            </OverlayPanel>
            {/*            <button
              className="ql-background"
              onClick={this.handleOnBackground}
            />
            <OverlayPanel ref={this.backgroundPickerRef} dismissable>
              <ChromePicker
                color={currentBackgroundPicker}
                onChange={this.handleOnChangeBackgroundPicker}
                onChangeComplete={this.handleOnChangeBackgroundPicker}
              />
            </OverlayPanel>*/}
            <span className="ql-formats">
              <button className="ql-align" value="" />
              <button className="ql-align" value="center" />
              <button className="ql-align" value="right" />
              <button className="ql-align" value="justify" />
            </span>
          </span>
        </div>
        <div
          id={`${name}-gk-qeditor`}
          className={`${singleLine ? "gk-editor-singleline" : ""}`}
        />
      </>
    );
  }
}

export const QuillEditorField = (props: any) => {
  const { name, values, singleLine, label, backgroundColor } = props;
  const editorName = props.name
    .replace(/\./g, "")
    .replace(/\[/g, "")
    .replace(/\]/g, "");

  const dispatch = useDispatch();
  const value = getIn(values, name, "");

  const handleOnChange = (value: any) => {
    const nc = { ...values };
    const response = setIn(nc, name, value.target.value);
    dispatch(updateCurrentCardAction(response));
  };

  return (
    <div className="p-field p-grid">
      {label && label !== "" && (
        <label
          htmlFor={editorName}
          className="p-col-fixed"
          style={{ width: "120px" }}
        >
          {label}
        </label>
      )}
      <div className="p-col">
        <QuillEditor
          name={editorName}
          singleLine={singleLine}
          backgroundColor={backgroundColor}
          onChange={handleOnChange}
          value={value}
        />
      </div>
    </div>
  );
};
