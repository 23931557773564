import Dropzone from "react-dropzone";
import React, { useState } from "react";
import { useHovering } from "use-hovering";
import "./uploaders.css";
import { CropModal } from "../cropModal/cropModal";
import { readFile } from "../../service/files";
import { ProgressSpinner } from "primereact/progressspinner";
import { v4 as uuidv4 } from "uuid";

const MAX_FILE_SIZE = 20 * 1048576; // 1MB

const cropperjsOptions = {
  initialAspectRatio: 16 / 9,
  aspectRatio: 16 / 9,
  guides: false,
  viewMode: 1,
};

const outputOptions = {};

export const GalleryUploader = ({
  onChange,
  onBlur,
  name,
  value,
  form,
  onAddImage,
  crop = true,
}: any) => {
  const ref = React.useRef<any>();
  const isHovered = useHovering(ref);

  const [loading, setLoading] = useState(false);
  const [showCrop, setShowCrop] = useState(false);
  const [files, setFiles] = useState<any>([]);

  const onDrop = async (acceptedFiles: any) => {
    if (acceptedFiles.length > 0) {
      setLoading(true);
      const reading = [];
      for (let i = 0; i < acceptedFiles.length; i++) {
        const pr = readFile(acceptedFiles[i]);
        reading.push(pr);
      }

      const results = await Promise.allSettled(reading);
      for (let i = 0; i < results.length; i++) {
        if (results[i].status === "fulfilled") {
          const file = (results[i] as any).value;
          file.uuid = uuidv4();
          if (crop && file.type.startsWith("image")) {
            files.push(file);
            setFiles(files);
            setShowCrop(true);
          } else {
            onAddImage(file);
          }
        }
      }

      setLoading(false);
    }
  };

  return (
    <div className="gallery-drop-zone" ref={ref}>
      {loading && (
        <div className="gallery-spinner">
          <ProgressSpinner style={{ transform: "scale(0.6)" }} />
        </div>
      )}
      {!loading && (
        <Dropzone
          onDrop={onDrop}
          maxFiles={0}
          maxSize={MAX_FILE_SIZE}
          multiple={true}
          noDragEventsBubbling={true}
        >
          {({ getRootProps, getInputProps }) => (
            <div
              className={`gallery-drop-target ${
                isHovered ? "gallery-drop-target-hover" : ""
              }`}
              {...getRootProps()}
            >
              <input {...getInputProps()} />
              <i className="pi pi-upload" style={{ fontSize: "1.5em" }} />
            </div>
          )}
        </Dropzone>
      )}
      <CropModal
        width={600}
        show={showCrop}
        files={files}
        round={false}
        onCancel={() => {
          setShowCrop(false);
          setFiles([]);
        }}
        onAcept={(file: any, isLast: boolean) => {
          setTimeout(() => {
            onAddImage(file);
            if (isLast) {
              setShowCrop(false);
              setFiles([]);
            }
          }, 1);
        }}
        croperjsOptions={cropperjsOptions}
        outputOptions={outputOptions}
      />
    </div>
  );
};
