import { GKBilling } from "gkshared";
import { v4 as uuidv4 } from "uuid";
import { Card } from "../models/card";

export const gkb = new GKBilling((process.env.APPLICATION_ID as string) || "")

export const UploadCardImages = async (oldCard: Card): Promise<Card> => {
  if (!oldCard) {
    return oldCard;
  }

  const card = JSON.parse(JSON.stringify(oldCard));
  try {
    if (card.Contact?.UserImage?.startsWith("data:")) {
      const res = await uploadData(card.Contact.UserImage, card.ID);
      card.Contact.UserImage = res;
    }

    if (card.Manifest?.Icon?.startsWith("data:")) {
      const res = await uploadData(card.Manifest.Icon, card.ID, undefined, false, true);
      card.Manifest.Icon = res;
    }

    if (card.Header?.Logo?.startsWith("data:")) {
      const res = await uploadData(card.Header.Logo, card.ID);
      card.Header.Logo = res;
    }

    for (let i = 0; i < card.Header.Slider.Slides.length; i++) {
      const slide = card.Header.Slider.Slides[i];
      slide.Order = i + 1;
      if (slide.Url.startsWith("data:")) {
        const res = await uploadData(slide.Url, card.ID, undefined, true);
        slide.Url = res;
        const parts = res.split("/");
        const name = parts.pop();
        slide.Thumb = res.replace(name, "_" + name);
      }
    }

    for (let i = 0; i < card.Sections.length; i++) {
      // Uploads multimedia gallery data
      const s = card.Sections[i];
      s.Order = i + 1;
      if (s.Type === 2) {
        for (let j = 0; j < s.GalleryData.length; j++) {
          const gd = s.GalleryData[j];
          gd.Order = j + 1;
          if (gd.Url.startsWith("data:")) {
            const res = await uploadData(gd.Url, card.ID, gd.Original);
            s.GalleryData[j] = { ...s.GalleryData[j], Url: res };
          }
          if (gd.CoverUrl.startsWith("data:")) {
            const res = await uploadData(gd.CoverUrl, card.ID, gd.Original);
            s.GalleryData[j] = { ...s.GalleryData[j], CoverUrl: res };
          }
        }
      } else if (s.Type === 7) {
        for (let j = 0; j < s.DownloadsData.length; j++) {
          const gd = s.DownloadsData[j];
          gd.Order = j + 1;
          if (gd.Url.startsWith("data:")) {
            const res = await uploadData(gd.Url, card.ID, gd.Original);
            s.DownloadsData[j] = { ...s.DownloadsData[j], Url: res };
          }
        }
      } else if (s.Type === 10) {
        if (s.ProfileData.UserImage.startsWith("data:")) {
          const res = await uploadData(s.ProfileData.UserImage, card.ID);
          s.ProfileData.UserImage = res;
        }
      } else if (s.Type === 3) {
        const text = await processBlobData(s.HtmlData.Text, card.ID);
        card.Sections[i].HtmlData.Text = text;
      }
    }
  } catch (err) {
    console.log(err);
  }

  return card;
};

async function processBlobData(data: string, cardID: number) {
  var tmp = document.createElement("div");
  tmp.innerHTML = data;
  const videos = tmp.getElementsByTagName("video");
  const sources = tmp.getElementsByTagName("source");

  if (sources && sources.length > 0) {
    for (let i = 0; i < sources.length; i++) {
      const src = sources[i].src;
      if (src.startsWith("data:")) {
        const aux = await uploadBase64(src, cardID);
        sources[i].src = aux;
      }
    }
  }

  if (videos && videos.length > 0) {
    for (let i = 0; i < videos.length; i++) {
      const poster = videos[i].poster;
      if (poster.startsWith("blob:")) {
        const aux = await uploadBlob(poster, cardID);
        videos[i].poster = aux;
      }
    }
  }

  return tmp.innerHTML;
}

async function uploadBlob(blobInfo, cardID, fn?) {
  let blobUrl = "";
  if (typeof blobInfo === "string") {
    blobUrl = blobInfo;
  } else {
    blobUrl = blobInfo.blobUri();
    fn = blobInfo.filename();
  }

  return await gkb.UploadBlob(blobUrl, cardID, fn);
}

async function uploadBase64(data, cardID) {
  const parts = data.split(";");
  const type = parts[0].replace("data:", "");
  const blobUrl = b64toBlob(parts[1], type);

  const dummyname = "dummy." + type.split("/")[1];
  return await uploadBlob(blobUrl, cardID, dummyname);
}

const b64toBlob = (b64Data, contentType = "", sliceSize = 512) => {
  if (b64Data.startsWith("base64,")) {
    b64Data = b64Data.replace("base64,", "");
  }

  const byteCharacters = atob(b64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, { type: contentType });
  const blobUrl = URL.createObjectURL(blob);
  return blobUrl;
};

export const uploadData = async (
  content: string,
  cardID = 0,
  original?: string,
  createThumb = false,
  createIcons = false
): Promise<any> => {
  const uid = uuidv4();
  let type = "";
  if (original) {
    type = original.split(".").pop();
  } else {
    type = getMediaType(content);
  }
  const filename = `${uid}.${type}`;
  return await uploadMedia({ content, filename, cardID, createThumb, createIcons });
};

export const getMediaType = (content: string) => {
  return content.split(";")[0].split("/")[1];
};

export const uploadMedia = async ({
  content,
  filename,
  cardID,
  createThumb,
  createIcons,
}: any): Promise<any> => {
  const blob: any = dataURItoBlob(content);
  return await gkb.UploadBlob(blob, cardID, filename, undefined, createThumb, createIcons);
};

function dataURItoBlob(dataURI: string) {
  // convert base64 to raw binary data held in a string
  // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
  const byteString = atob(dataURI.split(",")[1]);

  // separate out the mime component
  const mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];

  // write the bytes of the string to an ArrayBuffer
  const ab = new ArrayBuffer(byteString.length);

  // create a view into the buffer
  const ia = new Uint8Array(ab);

  // set the bytes of the buffer to the correct values
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }

  // write the ArrayBuffer to a blob, and you're done
  return new Blob([ab], { type: mimeString });
}
