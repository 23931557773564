import { ChromePicker } from "react-color";
import React, { useEffect, useRef, useState } from "react";

import "./inputs.css";
import { getIn, setIn } from "../../utils";
import { useDispatch } from "react-redux";
import { updateCurrentCardAction } from "../../store/application";
import { OverlayPanel } from "../primereact/components/overlaypanel/OverlayPanel";

const ColorPicker = ({ onChange, value, name }: any) => {
  useEffect(() => {
    setCurrentColor(value);
  }, [value]);
  const op = useRef<any>(null);

  const [currentColor, setCurrentColor] = useState("");
  const handleOnChange = (color: any) => {
    onChange(color.hex);
    setCurrentColor(color.hex);
  };

  return (
    <>
      <div
        role={"button"}
        tabIndex={0}
        className="color-picker-wrapper"
        onClick={(e) => op.current.toggle(e)}
      >
        <div style={{ backgroundColor: currentColor }} />
      </div>
      <OverlayPanel ref={op} dismissable>
        <ChromePicker
          color={currentColor}
          onChange={handleOnChange}
          onChangeComplete={handleOnChange}
        />
      </OverlayPanel>
    </>
  );
};

export const ColorField = ({ values, label, name, help }: any) => {
  const dispatch = useDispatch();
  const color = getIn(values, name, "");

  const handleOnChange = (color: string) => {
    const nc = { ...values };
    const response = setIn(nc, name, color);
    dispatch(updateCurrentCardAction(response));
  };

  return (
    <>
      <div className="p-field p-grid">
        <label
          htmlFor={name}
          className="p-col-fixed"
          style={{ width: "120px" }}
        >
          {label}
        </label>
        <div className="p-col">
          <ColorPicker
            value={color}
            id={name}
            name={name}
            style={{ width: 400 }}
            tooltip={help}
            tooltipOptions={{ position: "right" }}
            format="rgb"
            inline={true}
            onChange={handleOnChange}
          />
        </div>
      </div>
    </>
  );
};
