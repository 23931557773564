import * as React from "react";
import toPath from "lodash/toPath";
import clone from "lodash/clone";

export const cardPreviewBase = `https://v2.gkd.es`;

export const isEmptyArray = (value?: any) =>
  Array.isArray(value) && value.length === 0;

export const isFunction = (obj: any): obj is Function =>
  typeof obj === "function";

export const isObject = (obj: any): obj is Object =>
  obj !== null && typeof obj === "object";

export const isInteger = (obj: any): boolean =>
  String(Math.floor(Number(obj))) === obj;

export const isString = (obj: any): obj is string =>
  Object.prototype.toString.call(obj) === "[object String]";

// eslint-disable-next-line no-self-compare
export const isNaN = (obj: any): boolean => obj !== obj;

export const isEmptyChildren = (children: any): boolean =>
  React.Children.count(children) === 0;

export const isPromise = (value: any): value is PromiseLike<any> =>
  isObject(value) && isFunction(value.then);

export const isInputEvent = (value: any): value is React.SyntheticEvent<any> =>
  value && isObject(value) && isObject(value.target);

export function getIn(
  obj: any,
  key: string | string[],
  def?: any,
  p: number = 0
) {
  const path = toPath(key);
  while (obj && p < path.length) {
    obj = obj[path[p++]];
  }
  return obj === undefined ? def : obj;
}

export function setIn(obj: any, path: string, value: any): any {
  let res: any = clone(obj); // this keeps inheritance when obj is a class
  let resVal: any = res;
  let i = 0;
  let pathArray = toPath(path);

  for (; i < pathArray.length - 1; i++) {
    const currentPath: string = pathArray[i];
    let currentObj: any = getIn(obj, pathArray.slice(0, i + 1));

    if (currentObj && (isObject(currentObj) || Array.isArray(currentObj))) {
      resVal = resVal[currentPath] = clone(currentObj);
    } else {
      const nextPath: string = pathArray[i + 1];
      resVal = resVal[currentPath] =
        isInteger(nextPath) && Number(nextPath) >= 0 ? [] : {};
    }
  }

  // Return original object if new value is the same as current
  if ((i === 0 ? obj : resVal)[pathArray[i]] === value) {
    return obj;
  }

  if (value === undefined) {
    delete resVal[pathArray[i]];
  } else {
    resVal[pathArray[i]] = value;
  }

  // If the path array has a single element, the loop did not run.
  // Deleting on `resVal` had no effect in this scenario, so we delete on the result instead.
  if (i === 0 && value === undefined) {
    delete res[pathArray[i]];
  }

  return res;
}

export const arrayHelpers = {
  move: (array: any[], from: number, to: number) => {
    const copy = copyArrayLike(array);
    const value = copy[from];
    copy.splice(from, 1);
    copy.splice(to, 0, value);
    return copy;
  },
  swap: (arrayLike: ArrayLike<any>, indexA: number, indexB: number) => {
    const copy = copyArrayLike(arrayLike);
    const a = copy[indexA];
    copy[indexA] = copy[indexB];
    copy[indexB] = a;
    return copy;
  },
  insert: (arrayLike: ArrayLike<any>, index: number, value: any) => {
    const copy = copyArrayLike(arrayLike);
    copy.splice(index, 0, value);
    return copy;
  },
  remove: (arrayLike: ArrayLike<any>, index: number) => {
    const copy = copyArrayLike(arrayLike);
    copy.splice(index, 1);
    return copy;
  },
  replace: (arrayLike: ArrayLike<any>, index: number, value: any) => {
    const copy = copyArrayLike(arrayLike);
    copy[index] = value;
    return copy;
  },
};

const copyArrayLike = (arrayLike: ArrayLike<any>) => {
  if (!arrayLike) {
    return [];
  } else if (Array.isArray(arrayLike)) {
    return [...arrayLike];
  } else {
    const maxIndex = Object.keys(arrayLike)
      .map((key) => parseInt(key))
      .reduce((max, el) => (el > max ? el : max), 0);
    return Array.from({ ...arrayLike, length: maxIndex + 1 });
  }
};

export const getFileIconFromType = (type: string): string => {
  switch (type) {
    case "application/pdf":
      type = "pdf";
      break;
    case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
    case "application/doc":
    case "application/ms-doc":
    case "application/msword":
    case "application/vnd.oasis.opendocument.text":
    case "text/rtf":
      type = "word";
      break;
    case "application/vnd.ms-excel":
    case "application/excel":
    case "application/x-excel":
    case "application/x-msexcel":
    case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
    case "application/vnd.oasis.opendocument.spreadsheet":
      type = "excel";
      break;
    case "application/mspowerpoint":
    case "application/powerpoint":
    case "application/vnd.ms-powerpoint":
    case "application/x-mspowerpoint":
    case "application/vnd.openxmlformats-officedocument.presentationml.presentation":
    case "application/vnd.oasis.opendocument.presentation":
      type = "ppt";
      break;
    case "image/jpeg":
    case "image/pjpeg":
    case "image/png":
    case "image/gif":
    case "image/tiff":
    case "image/x-tiff":
    case "image/x-icon":
    case "image/svg+xml":
    case "image/webp":
      type = "image";
      break;
    case "application/x-troff-msvideo":
    case "video/avi":
    case "video/msvideo":
    case "video/x-msvideo":
    case "video/quicktime":
    case "video/x-quicktime":
    case "image/mov":
    case "audio/aiff":
    case "audio/x-midi":
    case "video/mp4":
    case "application/mp4":
    case "video/ogg":
    case "video/x-ms-wmv":
    case "video/x-ms-asf":
    case "video/webm":
      type = "video";
      break;
    case "audio/mpeg3":
    case "audio/x-mpeg-3":
    case "video/mpeg":
    case "video/x-mpeg":
    case "audio/wav":
    case "audio/x-wav":
    case "audio/ogg":
      type = "audio";
      break;
  }

  return type;
};
